import { globalGetService } from '../../../utils/globalApiServices';
import { ExtVettingRecord } from './types';

export const getVettingRecords = async (brandUid: string) => {
  const response = await globalGetService(`csp/brands/${brandUid}/vettings`, {
    vettingStatus: 'ACTIVE,PENDING,UNSCORE,FAILED,EXPIRED',
  });
  if (response.ok) {
    return response.data as ExtVettingRecord[];
  }
  return [];
};
