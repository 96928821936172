import {
  globalPostService,
  globalGetService,
  globalPutService,
  globalDeleteService,
} from '../../../utils/globalApiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';

export const createNewBrandApi = (data) => {
  return globalPostService('csp/brands', data);
};
export function getBrandDetails(id) {
  this.setState({ loader: true });
  globalGetService(`csp/brands/${id}`).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        brand: response.data,
        id,
        loader: false,
      });
    } else if (
      response.status === 400 &&
      response?.data?.code === 502 &&
      response?.data?.error[''][0] === 'Brand not found'
    ) {
      this.setState({
        notFound: true,
      });
    }
  });
}

export function hasBrand(id) {
  return globalGetService(`csp/brands/${id}`).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return true;
    } else if (
      response.status === 400 &&
      response?.data?.code === 502 &&
      response?.data?.error[''][0] === 'Brand not found'
    ) {
      toastFlashMessage('Brand not found', 'error');
      return false;
    }
  });
}
export function updateBrandApi(id, data) {
  this.setState({ editLoader: true });
  globalPutService(`csp/brands/${id}`, data).then((response) => {
    this.setState({ editLoader: false });
    if (response.status >= 200 && response.status < 300) {
      toastFlashMessage('Brand updated successfully', 'success');
      this.setState({
        brand: response.data,
        openEditModal: false,
      });
    } else {
    }
  });
}
export function getBrandFeedbackApi(id) {
  globalGetService(`csp/brands/${id}/feedback`).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        feedbackInfo: response.data.category,
      });
    }
  });
}
export function getBrandsList(query = {}) {
  this.setState({ tableLoader: true });
  globalGetService('csp/brands', query).then((response) => {
    this.setState({ tableLoader: false });
    if (response && response.status >= 200 && response.status < 300) {
      const brandInfo = response.data;
      this.setState((prevState) => ({
        ...prevState,
        brandInfo,
        loader: false,
        noBrandsFound:
          response.data.totalRecords == 0 &&
          Object.keys(this.state.appliedFilters).length == 0
            ? true
            : false,
      }));
      if (
        brandInfo.records.length == 0 &&
        brandInfo.totalRecords > 0 &&
        brandInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(brandInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: false }
          )}`,
        });
      }
    }
  });
}
export function getVerticalTypes() {
  globalGetService('enums/verticalTypes').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const verticalTypes = response.data.map((item) => {
        return { label: item.displayName, value: item.id };
      });
      this.setState((prevState) => ({
        ...prevState,
        verticalTypes,
      }));
    }
  });
}

export function getAllStockExchanges() {
  globalGetService('enums/stockExchanges').then((response) => {
    // console.log('response', response)
    if (response && response.status >= 200 && response.status < 300) {
      const stockExchanges = response.data.map((item) => {
        return { label: item, value: item };
      });
      this.setState((prevState) => ({
        ...prevState,
        stockExchanges,
      }));
    }
  });
}

export function findAllVerticalsApi() {
  globalGetService('enums/verticalTypes').then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const verticalTypes = response.data.map((item) => {
        return { label: item.displayName, value: item.id };
      });
      this.setState((prevState) => ({
        ...prevState,
        verticalTypes,
      }));
    }
  });
}

export function getExtVettingProviders() {
  globalGetService('csp/evp').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const vettingProviders = response.data;
      this.setState((prevState) => ({
        ...prevState,
        vettingProviders,
      }));
    }
  });
}

export function getEvpsByVettingClassApi() {
  globalGetService('csp/evp/byVettingClass').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const vettingClasses = {
        ...response.data,
      };
      this.setState({ vettingClasses });
    }
  });
}

export function grantExtVettingProviderBrandAccess(brandUid, evpUid) {
  this.setState({ vettingLoader: true });
  globalPutService(`csp/brands/${brandUid}/evp/${evpUid}`).then((response) => {
    // console.log('response -> grantExtVettingProviderBrandAccess', response)
    this.setState({ vettingLoader: false });
    if (response && response.status >= 200 && response.status < 300) {
      this.setState((prevState) => ({
        ...prevState,
        modalType: 2,
      }));
    }
  });
}

export function applyForNewVettingApi(brandUid, evpUid, vettingClass, data) {
  globalPostService(`csp/brands/${brandUid}/vettings/${evpUid}`, data).then(
    (response) => {
      // console.log('response applyForNewVettingApi', response)
      if (response && response.status >= 200 && response.status < 300) {
        const vettingConfirmed = Boolean(
          response.data.score &&
            response.data.vettedDate &&
            response.data.vettingId
        );
        this.setState({
          modalType: 2,
          vettingLoader: false,
          vettingConfirmed,
          vettingId: response.data.vettingId,
          vettingScore: response.data.score,
        });
        this.fetchVettingRecords(brandUid);
      } else {
        const keyName = Object.keys(response.data.error)[0];
        const errorMsg = response.data.error[keyName][0];
        this.setState({
          modalType: 2,
          vettingLoader: false,
          vettingConfirmed: false,
          applyError: true,
          applyErrorMsg: errorMsg ? errorMsg : '',
        });
      }
    }
  );
}

export function importVettingRecordApi(
  brandUid,
  evpUid,
  vettingId,
  query = {}
) {
  globalGetService(
    `csp/brands/${brandUid}/vettings/${evpUid}/import/${encodeURIComponent(
      vettingId
    )}`,
    query
  )
    .then((response) => {
      console.log('response importVettingRecordApi', response);
      if (response && response.status >= 200 && response.status < 300) {
        const vettingConfirmed = Boolean(
          response.data.score &&
            response.data.vettedDate &&
            response.data.vettingId
        );
        this.setState({
          modalType: 4,
          vettingLoader: false,
          vettingConfirmed,
          vettingClass: response.data.vettingClass,
          vettingScore: response.data.score,
        });
        this.fetchVettingRecords(brandUid);
      } else {
        this.setState({
          importVettingError: true,
          vettingLoader: false,
          modalType: 4,
        });
      }
    })
    .catch((err) => {
      // console.log('response error', err)s
    });
}

export function getBrandWithEinApi(ein = '') {
  return globalGetService('csp/brands', { ein });
}

export function getBrandValidationRulesApi(query = { entityType: `BRAND` }) {
  globalGetService(`csp/validationRule`, query).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      this.setState((prevState) => ({
        ...prevState,
        validationRules: response.data.records,
      }));
    }
  });
}
export function revetBrandApi() {
  this.setState({ revetLoader: true });
  globalPutService(`csp/brands/${this.props.match.params.id}/vet`).then(
    (response) => {
      this.setState({ revetLoader: false });
      if (response && response.status >= 200 && response.status < 300) {
        toastFlashMessage('Brand resubmitted successfully', 'success');
        this.getBrandFeedbackApi(this.props.match.params.id);
        this.setState((prevState) => ({
          ...prevState,
          revetConfirm: false,
          brand: response.data,
        }));
      } else {
        const keyName = Object.keys(response.data.error)[0];
        const errorMsg = response.data.error[keyName][0];
        this.setState({
          revetConfirm: false,
          modalOpenFlag: true,
          modalType: 2,
          applyError: true,
          applyErrorMsg: errorMsg ? errorMsg : '',
        });
      }
    }
  );
}

export function getBrandIdentityStatus() {
  globalGetService('enums/brandIdentityStatus').then((response) => {
    if (response.status >= 200 && response.status < 300) {
      if (response.data && Array.isArray(response.data)) {
        this.setState((prevState) => ({
          ...prevState,
          filterConfigs: {
            ...prevState.filterConfigs,
            identityStatus: {
              ...prevState.filterConfigs.identityStatus,
              options: response.data.map((status) => {
                return { label: status, value: status };
              }),
            },
          },
        }));
      }
    }
  });
}

export function deleteBrandApi(brandId) {
  return globalDeleteService(`csp/brands/${brandId}/softDelete`);
}

export const getUsStates = async () => {
  const response = await globalGetService('enums/usStates');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getCountries = async () => {
  const response = await globalGetService('enums/countries');
  if (response?.status >= 200 && response?.status < 300) {
    return response.data;
  }
  return [];
};

export const sendOtp = async (brandUid, message) => {
  const response = await globalPostService(`csp/brands/${brandUid}/otp`, {
    pinSms: message,
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const verifyOtp = async (brandUid, otp) => {
  const response = await globalPutService(`csp/brands/${brandUid}/otp`, {
    otpPin: otp,
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const getExtVettingReport = async (brandUid, folder) => {
  const response = await globalGetService(
    `csp/brands/${brandUid}/vettings/enhancedVettingReports?folder=${encodeURIComponent(
      folder
    )}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const downloadAttachmentApi = async (attachmentUuid) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadAttachmentToDesktop = async (attachmentUuid, fileName) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'blob'
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getMnoSuspensionStatus = async (brandUid) => {
  const response = await globalGetService(
    `csp/brands/${brandUid}/mnoSuspensionStatus`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const uploadAttachment = async (file) => {
  const data = new FormData();
  data.append('folderType', 'VET_APPEAL');
  data.append('file', file);
  const response = await globalPostService('csp/attachments', data);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const deleteAttachment = async (attachmentUuid) => {
  const encodedAttachmentUuid = encodeURIComponent(attachmentUuid);
  const response = await globalDeleteService(
    `csp/attachments/delete?attachmentUuid=${encodedAttachmentUuid}`
  );
  if (response.status >= 200 && response.status < 300) {
    return true;
  }
  throw new Error('API Error');
};

export const getAppealCategories = async () => {
  const response = await globalGetService('enums/appealCategories');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const getAppealHistory = async (brandUid) => {
  const encodedBrandUid = encodeURIComponent(brandUid);
  const response = await globalGetService(
    `csp/vetting/appeal?brandUid=${encodedBrandUid}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const requestIdentityVettingAppeal = async (
  brandUid,
  categories,
  explanation,
  attachments
) => {
  const response = await globalPostService(
    `csp/vetting/appeal/brand/${brandUid}/identity`,
    {
      evpUid: 'AEGIS',
      appealCategories: categories,
      explanation,
      attachmentUuids: attachments,
    }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const requestStandardVettingAppeal = async (
  brandUid,
  vettingId,
  categories,
  explanation,
  attachments
) => {
  const response = await globalPostService(
    `csp/vetting/appeal/brand/${brandUid}/standard`,
    {
      evpUid: 'AEGIS',
      vettingId,
      appealCategories: categories,
      explanation,
      attachmentUuids: attachments,
    }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};

export const getAppeal = async (vettingId) => {
  const response = await globalGetService(`csp/vetting/appeal/${vettingId}`);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API Error');
};
