import {
  Box,
  BoxV2,
  Button,
  MultimediaList,
  MultimediaUploader,
  AttachmentPreview,
} from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DeleteSampleMultimediaModal from './DeleteSampleMultimediaModal';
import UploadSizeExceededModal from './UploadSizeExceededModal';
import { Attachment, FileFormats } from '../types';
import { MAX_FILE_SIZE, MAX_SUPPORTING_DOCUMENT_COUNT } from '../constants';
import { downloadAttachmentToDesktop } from '../apiServices';
import {
  deleteAttachment,
  getAttachment,
  getAttachmentsForCampaign,
  getSupportedFileFormats,
  uploadAttachmentForCampaign,
} from '../apis';
import { AttachmentFolder } from '../enums';

interface SupportingDocumentSectionProps {
  readonly?: boolean;
  campaignUid: string;
}

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 430px;
  height: 226px;
  background: #ffffff;
`;

const SupportingDocumentSection: FunctionComponent<
  SupportingDocumentSectionProps
> = ({ readonly, campaignUid }) => {
  const [supportedFileFormats, setSupportedFileFormats] = useState<FileFormats>(
    {}
  );
  const [supportingDocument, setSupportingDocument] = useState<Attachment[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState<File | undefined>();
  const [previewIndex, setPreviewIndex] = useState(-1);
  const [files, setFiles] = useState<Record<string, File>>({});
  const previewUuid = supportingDocument[previewIndex]?.uuid;
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [sizeExceededModalOpen, setSizeExceededModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const fileFormats = await getSupportedFileFormats(
        AttachmentFolder.SupportingDocument
      );
      if (fileFormats) {
        setSupportedFileFormats(fileFormats);
      }
    })();
    (async () => {
      const supportingDocument = await getAttachmentsForCampaign(
        campaignUid,
        AttachmentFolder.SupportingDocument
      );
      if (supportingDocument) {
        setSupportingDocument(supportingDocument);
      }
    })();
  }, []);

  const handleChange = (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      setUpload(undefined);
      setSizeExceededModalOpen(true);
    } else {
      setUpload(file);
    }
  };

  const handleAdd = async () => {
    if (upload && supportingDocument.length < MAX_SUPPORTING_DOCUMENT_COUNT) {
      setLoading(true);
      const attachment = await uploadAttachmentForCampaign(
        campaignUid,
        AttachmentFolder.SupportingDocument,
        upload
      );
      if (attachment) {
        const newFiles = { ...files };
        newFiles[attachment.uuid] = upload;
        setFiles(newFiles);
        setSupportingDocument([...supportingDocument, attachment]);
        setUpload(undefined);
      }
      setLoading(false);
    }
  };

  const handleDownload = async (index: number) => {
    const uuid = supportingDocument[index]?.uuid;
    const fileName = supportingDocument[index]?.fileName;
    if (uuid && fileName) {
      await downloadAttachmentToDesktop(uuid, fileName);
    }
  };

  const handleSelect = async (index: number) => {
    setPreviewIndex(index);
    const uuid = supportingDocument[index]?.uuid;
    if (uuid && !files[uuid]) {
      const attachment = await getAttachment(uuid);
      if (attachment) {
        const file = new File(
          [attachment],
          supportingDocument[index].fileName,
          {
            type: supportingDocument[index].mimeType,
          }
        );

        const newFiles = { ...files };
        newFiles[uuid] = file;
        setFiles(newFiles);
      }
    }
  };

  const handleDelete = (index: number) => {
    setDeleteIndex(index);
  };

  const applyDelete = async () => {
    if (supportingDocument.length > deleteIndex) {
      const newSupportingDocument = [...supportingDocument];
      newSupportingDocument.splice(deleteIndex, 1);
      setSupportingDocument(newSupportingDocument);
      setDeleteIndex(-1);

      const uuid = await deleteAttachment(supportingDocument[deleteIndex].uuid);
      if (!uuid) {
        // revert supporting document if API call fails
        setSupportingDocument(supportingDocument);
      }
    }
  };

  return (
    <Grid item xs={12}>
      <div
        className="description details"
        data-testid="campaignDetailsSupportingDocument"
      >
        <div className="details-heading" style={{ borderBottom: 'none' }}>
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faBullhorn} size="xl" />
            </Box>
            <h3 className="heading1">
              CTA (Call-to-Action), Privacy Policy and/or Terms and Conditions
              Multimedia Upload
            </h3>
          </Box>
          <BoxV2
            sx={{
              marginTop: '8px',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16px',
            }}
          >
            {readonly
              ? 'Supporting information for opt in, call-to-action, terms and conditions, privacy policy, etc. Not intended for MMS sample messages.'
              : 'Provides an area to upload any supporting information for opt in, call-to-action, terms and conditions, privacy policy, etc. Not intended for MMS sample messages.'}
          </BoxV2>
        </div>
        <Grid container spacing={4}>
          {!readonly && (
            <Grid item xs={6}>
              <MultimediaUploader
                disabled={
                  supportingDocument.length >= MAX_SUPPORTING_DOCUMENT_COUNT
                }
                disabledText={`Max ${MAX_SUPPORTING_DOCUMENT_COUNT} uploads per campaign is allowed`}
                supportedMimeTypes={supportedFileFormats}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid item xs={6} style={{ minHeight: '307px' }}>
            <MultimediaList
              editable={!readonly}
              title="Multimedia Files"
              attachments={supportingDocument}
              loading={previewIndex > -1 && !files[previewUuid]}
              onDownload={handleDownload}
              onSelect={handleSelect}
              onDelete={handleDelete}
            />
            <DeleteSampleMultimediaModal
              open={!!supportingDocument[deleteIndex]}
              fileName={supportingDocument[deleteIndex]?.fileName}
              onCancel={() => setDeleteIndex(-1)}
              onSubmit={applyDelete}
            />
          </Grid>
          {supportingDocument.length < MAX_SUPPORTING_DOCUMENT_COUNT &&
            !readonly && (
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  data-testid="campaignDetailSupportingDocumentAddButton"
                  disabled={loading || !upload}
                  variant="standard"
                  shape="square"
                  color="primary"
                  size="small"
                  onClick={handleAdd}
                >
                  {loading ? 'Processing' : '+ Add Sample Multimedia'}
                </Button>
              </Grid>
            )}
          {!!files[previewUuid] && (
            <AttachmentPreview
              file={files[previewUuid]}
              mimeType={supportingDocument[previewIndex].mimeType}
              onClose={() => setPreviewIndex(-1)}
              data-testid="campaignDetailSupportingDocumentBackdrop"
            />
          )}
        </Grid>
      </div>
      <UploadSizeExceededModal
        open={sizeExceededModalOpen}
        onClose={() => setSizeExceededModalOpen(false)}
      />
    </Grid>
  );
};

export default SupportingDocumentSection;
