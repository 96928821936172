import {
  globalPostService,
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';
import queryString from 'query-string';
import { getTimezone } from '../../../utils/time';

const convertDateFormatForSearch = (inputDateStr) => {
  // inputDate: MM/dd/yyyy -> yyyy-MM-dd
  const parts = inputDateStr.split('/');
  if (parts.length === 3) {
    const [mm, dd, yyyy] = parts;
    return `${yyyy}-${mm}-${dd}`;
  }
  return inputDateStr;
};

const parseDateRange = (dateRange) => {
  const [startDate, endDate] = dateRange.replace(/\s/gi, '').split('-');
  return {
    startDate: convertDateFormatForSearch(startDate),
    endDate: convertDateFormatForSearch(endDate),
  };
};

export function getEventHistory(query = {}) {
  this.setState({ tableLoader: true });
  if (query.hasOwnProperty('dateRange')) {
    query = {
      ...query,
      ...parseDateRange(query.dateRange),
    };
    delete query.dateRange;
  }

  globalGetService('csp/event', { ...query, timezone: getTimezone() }).then(
    (response) => {
      this.setState({ tableLoader: false });
      if (response && response.status >= 200 && response.status < 300) {
        const eventInfo = response.data;
        eventInfo.recordsPerPage = eventInfo.recordsPerPage
          ? eventInfo.recordsPerPage
          : 10; // handling the backend bug
        this.setState((prevState) => ({
          ...prevState,
          eventInfo,
          loader: false,
        }));
        if (
          eventInfo.records.length == 0 &&
          eventInfo.totalRecords > 0 &&
          eventInfo.page > 1
        ) {
          const lastPageNo = Math.ceil(eventInfo.totalRecords / 10);
          this.setState({
            loader: true,
          });
          this.props.history.push({
            search: `?${queryString.stringify(
              { ...query, page: lastPageNo },
              { encode: false }
            )}`,
          });
        }
      }
    }
  );
}

export const downloadEventHistoryAsCsv = async (query = {}) => {
  if (query.hasOwnProperty('dateRange')) {
    query = {
      ...query,
      ...parseDateRange(query.dateRange),
    };
    delete query.dateRange;
  }

  const response = await globalGetService(
    'csp/event/csv',
    { ...query, timezone: getTimezone() },
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export function getAllEventTypes() {
  globalGetService('enums/eventTypes').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const eventTypes = response.data.map((event) => ({
        label: event.id,
        value: event.id,
      }));
      this.setState((prevState) => ({
        ...prevState,
        filterConfigs: {
          ...prevState.filterConfigs,
          eventType: {
            ...prevState.filterConfigs.eventType,
            options: eventTypes,
          },
        },
      }));
    }
  });
}

export function getAllBrands() {
  globalGetService('csp/brands', { recordsPerPage: 1000 }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const brandsOptions =
        response.data &&
        response.data.records.map((brand) => ({
          label: brand.displayName,
          value: brand.uid,
        }));
      this.setState((prevState) => ({
        ...prevState,
        filterConfigs: {
          ...prevState.filterConfigs,
          brandUid: {
            ...prevState.filterConfigs.brandUid,
            options: brandsOptions,
          },
        },
      }));
    }
  });
}
